import React from 'react'
import { useEventContext } from '../../../../../contexts/EventProvider'
import useScrollTopOnMount from '../../../../../hooks/useScrollTopOnMount'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Card from '../../../../atoms/Card'
import { claimMedia } from '../../../../../../api/app/media'
import { postRSVP, updateRSVP } from '../../../../../../api/actions'
import { Image, Heading, Text, VStack } from '@chakra-ui/react'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import schemaContributorRecipient from '../../Published/UploadThankYou/AddRecipient/ContributorCaptureForm/schema'
import { FormProvider, useForm } from 'react-hook-form'
import { addFlashMessage } from '../../../../../../api/app/behaviors/flash-messages'
import {
	getUserLocation,
	checkAccount,
	verifyEmail,
	registerUser,
} from '../../../../molecules/controls/InputEmail/utils'
import { REGISTER_SUCCESS } from '../../../../../../api/app/constants'
import { yupResolver } from '@hookform/resolvers/yup'
import ContributorCaptureForm from '../../Published/UploadThankYou/AddRecipient/ContributorCaptureForm'
import { useTemporaryUserContext } from '../../../../../contexts/TemporaryUserProvider'

/**
 * Scroll top utility
 */
const scrollTop = () => {
	if (typeof window !== 'undefined' && window) {
		window.scrollTo(0, 0)
	}
}

/**
 * Page returning the contributor form, available after
 * submitting media and when user isn't authenticated.
 */
const Submit = () => {
	useScrollTopOnMount()

	const { event } = useEventContext()
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	// const userLocation = useUserLocation()
	const handlers = useEventHandlersV2(event.uuid)

	const uploadsFromStore = useSelector((s) => s.uploader.uploads, shallowEqual)
	const uploads = uploadsFromStore?.length > 0 ? uploadsFromStore : location.state?.uploads

	const temporaryUser = useTemporaryUserContext()

	let creator = event.creator?.profile
	let creatorName = creator ? `${creator.firstName}` : 'them'

	const img = useImageGenerator({
		path: '/assets/images/graphics/graphic-fireworks',
		ext: 'png',
	})

	const methods = useForm({
		defaultValues: {
			// email: null,
			// firstName: null,
			// lastName: null,
			notifications: { promos: false },
			useConsent: false,
		},
		resolver: yupResolver(schemaContributorRecipient),
	})

	const { setError } = methods

	const onSubmit = async (data) => {
		/** Format email to be fullproof */
		data.email = String(data.email).trim().toLowerCase()
		/** Prepare an account object */
		let account
		/** First, let's make sure the email is valid. */
		const verify = await verifyEmail(data.email)
		/** If the email is not valid, let the user know */
		if (!verify.isValid) {
			setError('email', {
				type: 'error',
				message: 'Invalid email, please check your entry.',
			})
		} else {
			/** Ig yhr rmsil is valid, let's check to see if the account exists or not. */
			/** If it is valid, proceed with registration + Submission */
			/** First, let's check the account to see if it exist */
			const check = await checkAccount(data.email)
			const mediaLinks = []
			/** If the account check wasn't successful */
			if (!check.success) {
				dispatch(addFlashMessage('error', check.message))
			} else {
				/**
				 * Otherwise, we were able to verify the account.
				 */
				/** If the account already exist, retrieve the user */
				if (check.reason == 'ACCOUNT_EXISTS') {
					account = check.data
				} else if (check.reason == 'ACCOUNT_AVAILABLE') {
					let user = {
						...data,
						sendEmail: false, // Create the user only, don't send out email.
						setRegistered: false, // This user did not fill out the full register form
						location: null,
					}

					// Retrieve user location
					const userLocation = await getUserLocation()

					// Attach user location from state
					if (userLocation.success) {
						user.location = userLocation.data
					}

					/** Request to register the user */
					const registration = await registerUser(user)

					if (!registration.success) {
						dispatch(addFlashMessage('error', 'An error occured while trying to create an account.'))
					} else {
						/**
						 * Registration was processed successfully,
						 * let's update our user
						 *  */
						account = registration.data.data
						// Let's save our new user temporarely for compatibility
						dispatch({ type: REGISTER_SUCCESS, payload: registration.data })
					}
				}

				/** We have a user account, lets proceed */
				uploads.forEach((file) => {
					let newFile = { ...file }
					newFile.contributorId = account.id
					newFile.eventId = event.id
					newFile.socketReloadPage = true
					newFile.useConsent = data.useConsent
					// Workaround for claim media happening on failed uploads - only claim if id is set
					if (newFile.id) {
						console.log('Media has ID', newFile.id)
						dispatch(claimMedia(newFile))
					}
					// Add the source link to nav state for download
					mediaLinks.push(file.signedSourceUrl)
				})

				/** Create or update RSVP for this user and this event */
				const rsvp = {
					userId: account?.id,
					deliveryEmail: account?.email || data?.deliveryEmail || undefined,
					deliverySms: account?.profile?.sms || data?.deliverySms || undefined,
					firstName: data?.firstName || undefined,
					lastName: data?.lastName || undefined,
					eventId: event.id,
					status: uploads.length > 0 ? 'completed' : 'confirmed', // If we have uploads the status is 'complete'
				}

				/** Create or update RSVP for this user and this event */
				if (temporaryUser && temporaryUser.rsvp) {
					dispatch(
						updateRSVP({
							...rsvp,
							id: temporaryUser.rsvp,
						})
					)
				} else {
					dispatch(
						postRSVP({
							...rsvp,
							fromUpload: uploads.length > 0 ? true : false, // If we have uploads on create, it's from an upload.
							notify: false,
							muteAlert: true,
						})
					)
				}

				navigate(handlers.public_invite, {
					state: { fromUpload: true, user: account, mediaLinks: mediaLinks },
				})
			}
		}

		scrollTop()
	}

	/**
	 * Go back to main event page if user didn't arrive here from the upload page
	 */
	if (location.state?.fromUpload !== true) {
		return <Navigate to={handlers.public_root} />
	}

	/**
	 * Otherwise, Let's proceed with Contributor's information form
	 */
	return (
		<>
			<Helmet>
				<title>Submission received, who's it from?</title>
			</Helmet>

			<Card p="2rem">
				<VStack mb="2rem" w="full">
					<Image src={img.src} srcSet={img.srcset} alt="submission received" />
					<Heading color="dark" variant="hero" as="h2" size="lg">
						Almost done!
					</Heading>
					<Text color="dark" maxW="500px" textAlign="center">
						<strong>We got your submission, but before you go</strong> — let {creatorName} know who it's
						from, and we'll let you know if the video gift becomes available to watch.
					</Text>
				</VStack>
				<FormProvider {...methods}>
					<ContributorCaptureForm event={event} onSubmit={onSubmit} />
				</FormProvider>
			</Card>
		</>
	)
}

export default Submit
